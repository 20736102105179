<template>
  <ContentBlock>
    <div class="edit-role">
      <div class="edit-role__heading">
        <div class="subpage__title heading-3-sb grey-100">
          {{ pageTitle }}
        </div>
      </div>

      <div class="edit-role__input">
        <ValidationObserver ref="observer">
          <SlValidate
            v-slot="{ invalid }"
            :rules="idRules"
          >
            <SlInput
              v-model="catalogName"
              :label="$t('Web.Users.Name')"
              :is-invalid="invalid"
              max-length="64"
              required
            />
          </SlValidate>
        </ValidationObserver>
      </div>
      <div class="edit-role__input">
        <SlTextArea
          v-model="catalogDescription"
          height="80"
          :label="$t('Web.Users.Desc')"
          bottom-round
        />
      </div>
      <div class="edit-role__heading">
        <h5 class="heading-5-sb subpage__title">
          {{ $t('WiMultiuserTab.Ui.groupsBtn') }}
        </h5>
        <p class="body-1 grey-60">
          {{ $t('Web.Users.CatalogSubtitle') }}
        </p>
      </div>

      <SlTable
        v-if="catalogs"
        id="roles-tab"
        :headers="tableHeaders"
        :rows="tableRows"
        unique-key="id"
        header-unique-key="key"
        header-name-key="name"
        header-type-key="type"
        :row-height="40"
        :initial-col-sizes="['calc(100% - 200px)', '200px']"
        :max-height="1000"
        :col-resize="false"
        list
        expandable
      >
        <template #Name="slotScope">
          <div
            class="row__name-wrap"
            :style="{
              'padding-left': getRowPadding(slotScope)
            }"
          >
            <div class="row__name">
              <template v-if="slotScope.row && slotScope.row.expandable">
                <icon
                  data="@icons/chevron_right.svg"
                  class="fill-off size-16 color-grey-60"
                  :class="{
                    'rotate-90': isRowExpanded(slotScope)
                  }"
                  @click="toggleRow(slotScope)"
                />
                <icon
                  data="@icons/category.svg"
                  class="fill-off size-20"
                />
              </template>
              <icon
                v-if="!slotScope.row.expandable"
                data="@icons/circle_sm.svg"
                class="fill-off size-16"
              />
              {{ slotScope.cellValue.val }}
            </div>
          </div>
        </template>
        <template #Assignment="slotScope">
          <SlDropdown placement="bottom">
            <template #target="{ isShown }">
              <SlBadge
                class="row__assignment"
                :variant="getRowAssignment(slotScope.cellValue).class"
                type="editable"
              >
                <template #text>
                  {{ getRowAssignment(slotScope.cellValue).label }}
                </template>
                <icon
                  data="@icons/chevron_down.svg"
                  class="fill-off size-12"
                  :class="{
                    'rotate-180': isShown
                  }"
                />
              </SlBadge>
            </template>
            <template #content>
              <SlDropdownOption
                v-for="action in getRowAllowedAssignments(slotScope.cellValue)"
                :key="action.label"
                select-close
                class="dropdown-menu__button"
                @select="action.action(slotScope)"
              >
                <template #prepend>
                  <icon
                    :data="getIcon(action.icon)"
                    class="size-16 color-grey-50 fill-off"
                  />
                </template>

                <span class="row__assignment--option body-3-md">
                  {{ action.label }}
                </span>
              </SlDropdownOption>
            </template>
          </SlDropdown>
        </template>
      </SlTable>

      <div class="edit-role__footer">
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleBack"
        >
          {{ cancelButtonLabel }}
        </SlButton>
        <SlButton
          :disabled="!isEdited"
          @click="handleSave"
        >
          {{ saveButtonLabel }}
        </SlButton>
      </div>
    </div>
  </ContentBlock>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { actionsConfig, catalogAccessTypes, classByFlag, warningMessageMap } from '@/config/users/users.config';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'EditCatalog',
  mixins: [modal],
  data() {
    return {
      isRedirect: false,
      name: null,
      description: null,
      catalog_name: null,
      catalog_description: null,
      catalogAccessTypes,
      warningMessageMap,
      classByFlag,
      actionsConfig: actionsConfig(this)

    };
  },
  computed: {
    ...mapGetters({
      // TODO: norm error message
      hasLocation: 'project/hasLocation',
      hasChannel: 'project/hasChannel',
      takenNamesByKey: 'userAccess/takenNamesByKey'
    }),
    ...mapState('userAccess', [
      'catalogs',
      'catalog_id',
      'openCatalogNodes'
    ]),
    takenNames() {
      return this.takenNamesByKey('permissions');
    },
    isEditState() {
      return this.$sl_routeName === routeNames.EDIT_CATALOG;
    },
    catalogId() {
      return this.$route.params.catalogId;
    },
    idRules() {
      return {
        required: true,
        'sl_not_includes': {
          values: this.takenNames.filter(name => {
            if (this.isEditState) {
              return name !== this.name;
            }

            return true;
          }),
          message: this.$t('Web.Error.AlreadyExist', {
            1: this.$t('Web.Error.Names.Catalog')
          })
        }
      };
    },
    catalogName: {
      get() {
        return this.catalog_name ?? this.name;
      },
      set(value) {
        this.catalog_name = value;
      }
    },
    catalogDescription: {
      get() {
        return this.catalog_description ?? this.description;
      },
      set(value) {
        this.catalog_description = value;
      }
    },
    tableHeaders() {
      return this.catalogs.headers;
    },
    tableRows() {
      return this.catalogs.rows;
    },
    isEdited() {
      const isNameEdited = this.catalogName !== this.name;
      const isDescriptionEdited = this.catalogDescription !== this.description;
      const isCatalogTreeEdited = +this.catalogs?.metadata?.edited;

      return isNameEdited || isDescriptionEdited || isCatalogTreeEdited;
    },
    pageTitle() {
      return this.isEditState
        ? this.$t('EditInfo.EditCatalog')
        : this.$t('WiMultiuserTab.NewCatalog');
    },
    saveButtonLabel() {
      return this.isEditState
        ? this.$t('Web.Collections.SaveChanges')
        : this.$t('Web.Modals.BtnCreate');
    },
    cancelButtonLabel() {
      return this.isEditState
        ? this.$t('Web.Collections.Discard')
        : this.$t('Common.Cancel');
    }
  },
  async beforeMount() {
    if (this.catalogId) {
      await this.setCatalogTreeId(this.catalogId);
      await this.fetchCatalogAccessTree();

      this.name = this.catalogs.metadata.name;
      this.description = this.catalogs.metadata.description;

      return;
    }

    if (!this.catalog_id) {
      await this.createCatalogAccessTree();
      await this.fetchCatalogAccessTree();
    }
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    this.resetCatalogTree(this.catalog_id);
    next();
  },
  methods: {
    ...mapActions('userAccess', [
      'fetchCatalogAccessTree',
      'resetCatalogTree',
      'setCatalogTreeId',
      'createCatalogAccessTree',
      'changeCatalogAccessCatalog',
      'collapseCatalogAccessTree',
      'fetchCatalogTreeChildNodes',
      'createCatalog',
      'updateCatalog'
    ]),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    async onRouteLeave(next) {
      if (!this.isEdited) {
        await this.resetCatalogTree(this.catalog_id);

        return next();
      }

      const modalData = {
        icon: 'style_save_double',
        title:
            this.isEditState
              ? this.$t('Web.Modals.UnsavedConfirm.TitleLeavePage')
              : this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
        text:
            this.isEditState
              ? this.$t('Web.Modals.UnsavedConfirm.TextLeaveProject')
              : this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
        confirmText:
            this.isEditState
              ? this.$t('Web.Modals.UnsavedConfirm.ButtonLeave')
              : this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
        confirmCallback: async() => {
          await this.resetCatalogTree(this.catalog_id);

          this.isRedirect = true;

          next();
        }
      };

      this.showModal(modalsId.SL_CONFIRM_MODAL, modalData);
    },
    handleBack() {
      this.$router.push({
        name: routeNames.CATALOGS
      });
    },
    isRowExpanded(scope) {
      return this.openCatalogNodes.includes(scope.row?.id);
    },
    getRowPadding(scope) {
      return 8 + scope.row?.depth * 24 + 'px';
    },
    toggleRow(scope) {
      if (this.isRowExpanded(scope)) {
        return this.collapseCatalogAccessTree({
          index: scope.rowIndex,
          depth: scope.row.depth,
          nodeId: scope.row.id
        });
      }

      this.fetchCatalogTreeChildNodes({
        rowIndex: scope.rowIndex,
        type: scope.row.type,
        nodeId: scope.row.id
      });
    },
    handleChangeAccess(scope, status) {
      this.changeCatalogAccessCatalog({
        nodeId: scope.row.id,
        status
      });
    },
    getRowAssignment(cell) {
      const currentAction = this.actionsConfig.find(action => {
        return cell.fgs & action.flag;
      });

      const rowClass = this.classByFlag[cell.fgs];

      return {
        label: currentAction.label,
        class: rowClass
      };
    },
    getRowAllowedAssignments(cell) {
      return this.actionsConfig.filter(action => {
        return cell.availableActions & action.flag;
      });
    },
    validateTable() {
      const valid = this.tableRows.reduce((acc, row) => {
        if (!row.depth && !row.selected) {
          return false;
        }

        return acc;
      }, true);

      if (valid) {
        return { valid };
      }

      const invalidRow = this.tableRows.find(row => !row.depth && !row.selected);
      const i18nKey = this.warningMessageMap[invalidRow.type];

      return {
        valid: false,
        invalidMessage: this.$t(`WiMultiuserTab.Error.${i18nKey}`)
      };
    },
    async handleSave() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      const tableValidation = this.validateTable();

      if (!tableValidation.valid) {
        return this.$notify({
          type: 'error',
          title: this.$t('Web.Users.CatalogError'),
          text: tableValidation.invalidMessage,
          duration: 9000
        });
      }

      const payload = {
        name: this.catalogName,
        description: this.catalogDescription || ''
      };

      if (this.isEditState) {
        await this.updateCatalog(payload);
      } else {
        await this.createCatalog(payload);
      }

      this.isRedirect = true;

      this.handleBack();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/users/edit-role.scss";
@import "@/style/components/users/edit-catalog.scss";
</style>
