var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentBlock',[_c('div',{staticClass:"edit-role"},[_c('div',{staticClass:"edit-role__heading"},[_c('div',{staticClass:"subpage__title heading-3-sb grey-100"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])]),_c('div',{staticClass:"edit-role__input"},[_c('ValidationObserver',{ref:"observer"},[_c('SlValidate',{attrs:{"rules":_vm.idRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Users.Name'),"is-invalid":invalid,"max-length":"64","required":""},model:{value:(_vm.catalogName),callback:function ($$v) {_vm.catalogName=$$v},expression:"catalogName"}})]}}])})],1)],1),_c('div',{staticClass:"edit-role__input"},[_c('SlTextArea',{attrs:{"height":"80","label":_vm.$t('Web.Users.Desc'),"bottom-round":""},model:{value:(_vm.catalogDescription),callback:function ($$v) {_vm.catalogDescription=$$v},expression:"catalogDescription"}})],1),_c('div',{staticClass:"edit-role__heading"},[_c('h5',{staticClass:"heading-5-sb subpage__title"},[_vm._v(" "+_vm._s(_vm.$t('WiMultiuserTab.Ui.groupsBtn'))+" ")]),_c('p',{staticClass:"body-1 grey-60"},[_vm._v(" "+_vm._s(_vm.$t('Web.Users.CatalogSubtitle'))+" ")])]),(_vm.catalogs)?_c('SlTable',{attrs:{"id":"roles-tab","headers":_vm.tableHeaders,"rows":_vm.tableRows,"unique-key":"id","header-unique-key":"key","header-name-key":"name","header-type-key":"type","row-height":40,"initial-col-sizes":['calc(100% - 200px)', '200px'],"max-height":1000,"col-resize":false,"list":"","expandable":""},scopedSlots:_vm._u([{key:"Name",fn:function(slotScope){return [_c('div',{staticClass:"row__name-wrap",style:({
            'padding-left': _vm.getRowPadding(slotScope)
          })},[_c('div',{staticClass:"row__name"},[(slotScope.row && slotScope.row.expandable)?[_c('icon',{staticClass:"fill-off size-16 color-grey-60",class:{
                  'rotate-90': _vm.isRowExpanded(slotScope)
                },attrs:{"data":require("@icons/chevron_right.svg")},on:{"click":function($event){return _vm.toggleRow(slotScope)}}}),_c('icon',{staticClass:"fill-off size-20",attrs:{"data":require("@icons/category.svg")}})]:_vm._e(),(!slotScope.row.expandable)?_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/circle_sm.svg")}}):_vm._e(),_vm._v(" "+_vm._s(slotScope.cellValue.val)+" ")],2)])]}},{key:"Assignment",fn:function(slotScope){return [_c('SlDropdown',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"target",fn:function(ref){
                var isShown = ref.isShown;
return [_c('SlBadge',{staticClass:"row__assignment",attrs:{"variant":_vm.getRowAssignment(slotScope.cellValue).class,"type":"editable"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.getRowAssignment(slotScope.cellValue).label)+" ")]},proxy:true}],null,true)},[_c('icon',{staticClass:"fill-off size-12",class:{
                  'rotate-180': isShown
                },attrs:{"data":require("@icons/chevron_down.svg")}})],1)]}},{key:"content",fn:function(){return _vm._l((_vm.getRowAllowedAssignments(slotScope.cellValue)),function(action){return _c('SlDropdownOption',{key:action.label,staticClass:"dropdown-menu__button",attrs:{"select-close":""},on:{"select":function($event){return action.action(slotScope)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"size-16 color-grey-50 fill-off",attrs:{"data":_vm.getIcon(action.icon)}})]},proxy:true}],null,true)},[_c('span',{staticClass:"row__assignment--option body-3-md"},[_vm._v(" "+_vm._s(action.label)+" ")])])})},proxy:true}],null,true)})]}}],null,false,944507534)}):_vm._e(),_c('div',{staticClass:"edit-role__footer"},[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleBack}},[_vm._v(" "+_vm._s(_vm.cancelButtonLabel)+" ")]),_c('SlButton',{attrs:{"disabled":!_vm.isEdited},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(_vm.saveButtonLabel)+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }